import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class HttpService {
  baseUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseUrl = environment.baseUrl;
  }

  fullUrl(url) {
    return `${this.baseUrl}/${url}`;
  }

  get(url) {
    return this.httpClient.get(this.fullUrl(url)).toPromise();
  }

  post(url, data) {
    return this.httpClient.post(this.fullUrl(url), data).toPromise();
  }
}
