import { Component, OnInit, AfterViewInit } from "@angular/core";
import * as L from "leaflet";
import { HttpService } from "../../core/service/http.service";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
//import { ElasticService } from "../../shared/elastic.service";
import { ForetagService } from "../../shared/companyService";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit, AfterViewInit {
  map;
  details: any = {};
  title: string;

  constructor(
    private http: HttpService,
    private ellasticService: ForetagService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this.route.params.subscribe((params) => {
      this.getDetails(params.id);
    });
  }

  private initMap(): void {
    // console.log(this.details);
    if (this.details.latitude != null && this.details.longitude) {
      console.log("Latitude. " + this.details.latitude)
      console.log("Longitude. " + this.details.longitude)
      this.map = L.map("map", {
        center: [
          Number(this.details.latitude) - 0.001,
          Number(this.details.longitude) - 0.001,
        ],
        zoom: 11,
        worldCopyJump: true,
      });
      /*
      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        maxZoom: 15,
        attribution:
          '&copy; <a href="http://www.openstreetmap.org/copyright">Allinfo</a>'
      }).addTo(this.map);
      */
      L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
        maxZoom: 22,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }).addTo(this.map);
      const markerIcon = L.icon({
        iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
      });
      L.marker([this.details.latitude, this.details.longitude], {
        title: this.details.name,
        icon: markerIcon,
      }).addTo(this.map);
    } else {
      console.log("Long and Lat NULL");
    }
  }

  ngAfterViewInit() {
    // this.initMap();
  }

  ngOnInit() {
    this.spinner.show();
  }

  getDetails(i) {
    console.log(this.ellasticService.list);
    /*
    this.http.get(`search/single/${id}`).then((details: any) => {*/
    // details.icon = icon[ Math.round( Math.random() * icon.length-1 ) ];
    this.details = this.ellasticService.list[i];
    this.title = this.details.name;
    // console.log(details);
    setTimeout(() => {
      this.initMap();
      this.spinner.hide();
    }, 500);
  }
  onClick() {
    // window.location.href = this.details.homepage; // "https://www.google.com"
    window.open(this.details.homepage, "_blank");
  }
}

const icon = [
  "ion-paper-airplane",
  "ion-ios-football",
  "ion-beer",
  "ion-bonfire",
];
