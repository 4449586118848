import { Component, OnInit, HostListener } from '@angular/core';

const LOGO = {
  inverse: './assets/images/logo_inv.png',
  default: './assets/images/logo.png'
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public mainLogo: string = LOGO.default;

  constructor() { }

  ngOnInit() { }

  // @HostListener("window:scroll", [])
  // onWindowScroll() {

  //   const number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   if (number > 100) {
  //     this.mainLogo = LOGO.inverse;
  //   } else {
  //     this.mainLogo = LOGO.default;
  //   }

  // }

}
