import { Component, OnInit, Input } from '@angular/core';
import { IBreadCrumbs } from './bread-crumbs.model';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() breadcrumbs: IBreadCrumbs[];

  constructor() {
  }

  ngOnInit() {
    this.breadcrumbs = this.breadcrumbs || [ ];
    this.breadcrumbs = this.breadcrumbs.map(crumb => ({
      ...crumb,
      href: !crumb.href ? '#' : crumb.href,
      activeClass: crumb.active ? 'active': '',
    }));
  }

}
