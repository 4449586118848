declare var ol: any;
import * as L from "leaflet";

import { Component, OnInit, AfterViewInit } from "@angular/core";
import { HttpService } from "./../../service/http.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit, AfterViewInit {
  map: any;
  recaptcha: string;
  invalidForm = false;
  contactUs: any;
  mailSent = false;

  constructor(private http: HttpService) {}

  ngOnInit() {
    this.resetContactUs();
  }

  private initMap(): void {
    this.map = L.map("map", {
      center: [39.8282, -98.5795],
      zoom: 3,
    });
    /*
    const tiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      zIndex: -1
    });
    */
    const tiles = L.tileLayer(
      "http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      {
        maxZoom: 22,
        subdomains: ["mt0", "mt1", "mt2", "mt3"],
      }
    );
    tiles.addTo(this.map);
  }

  ngAfterViewInit() {
    this.initMap();
  }

  sendContactUsForm() {
    this.invalidForm = false;

    if (
      this.checkRequired(this.recaptcha) ||
      this.checkRequired(this.contactUs.name) ||
      this.checkRequired(this.contactUs.email) ||
      this.checkRequired(this.contactUs.comment)
    ) {
      return (this.invalidForm = true);
    }

    if (!this.validateEmail(this.contactUs.email)) {
      return (this.invalidForm = true);
    }

    this.http
      .post("contact/mail", {
        ...this.contactUs,
        recaptcha: this.recaptcha,
      })
      .then((r) => {
        this.recaptcha = null;
        this.resetContactUs();
        this.mailSent = true;
        console.log(r);
      });
  }

  resetContactUs() {
    return (this.contactUs = {
      name: null,
      email: null,
      phone: null,
      comment: null,
    });
  }

  checkRequired(x: string) {
    return !x || x.length === 0;
  }

  resolved(x) {
    this.recaptcha = x;
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
}
