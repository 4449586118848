import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { ForetagService } from "../../shared/companyService";
//import { environment } from "src/environments/environment";
import { Search } from "../../shared/sok-model";

declare const grecaptcha: any;

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  services: any = SERVICES;
  sites: any = SITES;
  reToken: any;
  _search: Search;

  constructor(private router: Router, private elasticSearch: ForetagService) { }

  ngOnInit() { }

  search(searchTerm, searchRef?) {
    this._search = searchTerm;
    console.log(this._search);
    this.elasticSearch.searchTerm = searchTerm;
    console.log(this.elasticSearch.searchTerm);
    this.router.navigate(["search", searchTerm]);
  }
}

const SERVICES = [
  {
    name: "Sociala Medier",
    description:
      "Detta är den nya grejen. Här får man exponeringen riktad på" +
      "människor som är av intresse för just er. Annonser kommer upp " +
      "i deras flöden, dagens generation tillbringar cirka 4 timmar i genomsnitt per dag på sociala " +
      "medier. Vi är en viktig förlängning av ditt varumårkes identitet. Medan vi lär känna ditt " +
      "varumärke inifrån och ut, kommer vi veta, var och när vi ska dela dina detaljer för att ge konkreta resultat. " +
      "Med hjälp från våra sociala medieexperter, din röst är högt och tydligt. kortfattad syns där din målgrupp finns‎.",
    icon: "ion-md-share",
  },
  {
    name: "Medierådgivning ",
    description:
      "Vår professionella medierådgivare hjälper dig att uppnå den största effekten med sin marknadsföring eller " +
      "information. Vi ansvarar för att sätta ihop kompletta marknadsföringspaket, där en kombination av annonsering " +
      "i lokala och/eller rikstäckande medier, nyhetssajter, nyhetsappar och på sociala medier. Vi se till att din " +
      "kampanj får så Stort genomslag som möjligt med maximal effekt. Vi utgår från dina mål och visioner för att kunna " +
      "skapa lösningar som ger bästa chansen att nå rätt målgrupper under dygnets alla timmar. ",
    icon: "ion-md-tv",
  },
  {
    name: "SEO",
    description:
      //
      "Till SEO hör både de tekniska och kreativa komponenterna som behövs för att förbättra placeringar, öka " +
      "medvetenhet och driva trafik i sökmotorer. Inom sökmotoroptimering finns det tusentals olika faktorer som " +
      "avgör vilken placering en webbplats får i Google och andra sökmotorer. Idag finns de flera olika sätt att " +
      "nå ut till kunderna via Google. Google My Business är en metod för att stärka ert varumärke. Medans Google-Ads " +
      "är en typ av sökoptimering för att er placering på specifika sökningar ska förbättras. Allt detta är vi proffs på.",
    icon: "ion-md-search",
  },
];

const SITES = [
  {
    image: "/assets/images/sites/ykkeurope.png",
    title: "Ykk Europe",
    website: "www.ykkeurope.com",
  },
  /*
  {
    image: "/assets/images/sites/advanderskarls.png",
    title: "Advokat Anders karls",
    website: "www.advanderskarls.se",
  },
  {
    image: "/assets/images/sites/vvent.png",
    title: "Värme Vent Ingenjörsbyrå",
    website: "www.vvent.se",
  },
  */
];
