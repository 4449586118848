import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./pages/home/home.component";
import { DetailsComponent } from "./pages/details/details.component";
import { PageWrapperComponent } from "./core/components/page-wrapper/page-wrapper.component";
import { ListingComponent } from "./pages/listing/listing.component";

const routes: Routes = [
  // { path: '', component: HomeComponent },
  {
    path: "",
    component: PageWrapperComponent,
    children: [
      { path: "", component: HomeComponent },
      { path: "details/:id", component: DetailsComponent },
      { path: "search/:searchTerm", component: ListingComponent },
      { path: "search/:searchTerm/:pageId", component: ListingComponent }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
