import { Injectable } from '@angular/core';

@Injectable()
export class Search {
  search_foretag: string;
  search_category: string;
  search_ort: string;
  constructor() {
  }
}
