import { Component, OnInit, AfterViewInit } from "@angular/core";
import * as L from "leaflet";
import { HttpService } from "../../core/service/http.service";
import { ForetagService } from "../../shared/companyService";
import { ActivatedRoute } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { Search } from "../../shared/sok-model";

@Component({
  selector: "app-listing",
  templateUrl: "./listing.component.html",
  styleUrls: ["./listing.component.scss"],
})
export class ListingComponent implements OnInit, AfterViewInit {
  listing: any[] = [];
  map;
  markerGroup;
  markerLatLng: Array<any> = [];
  _search: Search;
  searchQuery: string;
  totalResults = 0;
  pageNumber = 0;
  // searchTerm: string;

  constructor(
    private elasticService: ForetagService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService
  ) {
    this._search = new Search();
    /*
    this.route.params.subscribe(params => {
      this.searchTerm = params.searchTerm;
      this.search(this.searchTerm);
    });
    */
    //this.search(this.elasticService.getForetagNamn());
    /*
    console.log(this.elasticService.searchTerm);
    this.elasticService
      .getForetagNamn(this.elasticService.searchTerm)
      .subscribe(res => console.log(res));
    */
  }

  ngOnInit() {
    if (this.elasticService.searchTerm.length > 3) {
      console.log("Listing comp");
      this.searchQuery = this.elasticService.searchTerm;
      this._search.search_foretag = this.searchQuery;
      this.spinner.show();
      //console.log(searchTerm);
      console.log(this._search);
      this.elasticService
        .getsearchForetag(this._search.search_foretag)
        .subscribe((res: any) => {
          this.clearMarkers();
          console.log(res);
          if (res) {
            const results = res.l || res.data || res;
            this.elasticService.pageNumber = +res.page;
            this.elasticService.list = results;
            this.pageNumber = 1;
            //console.log(res.page);
            console.log("Page Namber :" + this.pageNumber);
            this.elasticService.items = +res.items;
            this.totalResults = results.length;
            console.log("Total " + results.length);
            if (results.length === 0) {
              this.spinner.hide();
              return;
            }
            this.setupList(results);
            this.spinner.hide();
          }
        });
      this.spinner.hide();
    }

  }

  goBack() {
    window.history.back();
  }

  setupList(items) {
    console.log("Setting upp the list");
    const markerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png",
    });
    // Had to add this other it was calling localhost/marker-icon.png (404 not found)
    try {
      this.listing = items.map((i: any) => {
        if (!!i.latitude && !!i.longitude) {
          const markerHTML = `<b>${i.name}</b><p>${!!i.address ? i.address : ""
            }</p><p>${!!i.phone1 ? i.phone1 : ""}</p>`;
          L.marker([i.latitude, i.longitude], {
            title: i.name,
            icon: markerIcon,
          })
            .bindPopup(markerHTML)
            .addTo(this.markerGroup);
          this.markerLatLng.push(new L.LatLng(i.latitude, i.longitude));
        }

        return {
          ...i,
          id: i.id,
          title: i.name,
          address: `${i.address}, ${i.postalcode}, ${i.city}`,
          phone: i.phone1,
          activity: i.activity,
          description: i.activity,
          category: i.category || i.category_id,
          latitude: Number(i.latitude),
          longitude: Number(i.longitude),
        };
      });
      this.fitBounds();
    } catch (ex) {
      console.error(ex);
    }

    this.spinner.hide();
  }

  fitBounds() {
    const bounds = new L.LatLngBounds(this.markerLatLng);
    this.map.fitBounds(bounds);
  }

  centerMarker(latitude, longitude, zoom = 10) {
    this.map.flyTo(new L.LatLng(latitude, longitude), zoom);
  }

  private initMap(): void {
    this.map = L.map("map", {
      center: [59.3326, 18.0649], // [39.8282, -98.5795],
      zoom: 10,
      worldCopyJump: true,
    });
    /*
    L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      // maxZoom: 10,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    }).addTo(this.map);
    */
    L.tileLayer("http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}", {
      maxZoom: 22,
      subdomains: ["mt0", "mt1", "mt2", "mt3"],
    }).addTo(this.map);

    this.markerGroup = L.layerGroup().addTo(this.map);
  }

  ngAfterViewInit() {
    this.initMap();
  }

  clearMarkers() {
    if (!!this.markerGroup) {
      this.markerGroup.clearLayers();
      this.markerLatLng = [];
    }
  }

  search(searchTerm: string, page = this.elasticService.pageNumber) {
    this.spinner.show();
    console.log("Listing comp");
    console.log(searchTerm);
    this._search.search_foretag = searchTerm;
    console.log(this._search);
    this.elasticService
      .getsearchForetag(this._search)
      .subscribe((res: any) => {
        this.clearMarkers();
        console.log(res);
        if (res) {
          const results = res.l || res.data || res;
          this.elasticService.pageNumber = +res.page;
          this.elasticService.list = results;
          this.pageNumber = +res.page;
          this.elasticService.items = +res.items;
          this.totalResults = res.total;
          if (results.length === 0) {
            this.spinner.hide();
            return;
          }
          this.setupList(results);
          this.spinner.hide();
        }
      });
    this.spinner.hide();
  }

  next() {
    /*
    console.log("next pressed");
    if (
      this.totalResults <
      +this.elasticService.items * this.elasticService.pageNumber
    ) {
      return;
    }
    this.elasticService.pageNumber = this.elasticService.pageNumber + 1;
    this.search(this.elasticService.searchTerm);
    */
  }

  prev() {
    /*
    console.log("prev pressed");
    const page = this.elasticService.pageNumber - 1;
    if (page < 1) {
      return;
    }
    this.elasticService.pageNumber = this.elasticService.pageNumber - 1;
    this.search(this.elasticService.searchTerm);
    */
  }

}
