import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./pages/home/home.component";
import { DetailsComponent } from "./pages/details/details.component";
import { CoreModule } from "./core/core.module";
import { ListingComponent } from "./pages/listing/listing.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpService } from "./core/service/http.service";
import { ForetagService } from './shared/companyService';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DetailsComponent,
    ListingComponent
  ],
  imports: [

    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    CoreModule,
    HttpClientModule,
    NgxSpinnerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ForetagService, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { } /*{
  constructor(private http: HttpService) {
    this.http
      .get("config")
      .then(config => {
        localStorage.setItem("config", JSON.stringify(config));
      })
      .catch(e => console.error(e));
  }*/
