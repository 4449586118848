import { TranslateModule } from "@ngx-translate/core";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SectionHeaderComponent } from "./components/section-header/section-header.component";
import { NavComponent } from "./components/nav/nav.component";
import { HeaderComponent } from "./components/header/header.component";
import { PageWrapperComponent } from "./components/page-wrapper/page-wrapper.component";
import { FooterComponent } from "./components/footer/footer.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { PageHeaderComponent } from "./components/page-header/page-header.component";
import { HttpService } from "./service/http.service";
import { HttpClientModule } from "@angular/common/http";
import {
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaModule,
  RECAPTCHA_LANGUAGE,
} from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    SectionHeaderComponent,
    NavComponent,
    HeaderComponent,
    PageWrapperComponent,
    FooterComponent,
    PageHeaderComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    RecaptchaModule,
    FormsModule,
  ],
  exports: [
    SectionHeaderComponent,
    HeaderComponent,
    FooterComponent,
    PageWrapperComponent,
    PageHeaderComponent,
  ],
  providers: [
    HttpService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: { siteKey: environment.recaptchaSiteKey } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: "sv", // use French language
    },
  ],
})
export class CoreModule {}
