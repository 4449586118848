import { Component, OnInit, Input } from '@angular/core';
import { IBreadCrumbs } from '../page-header/bread-crumbs.model';

@Component({
  selector: 'app-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss']
})
export class PageWrapperComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle: string;
  @Input() breadcrumbs: IBreadCrumbs[];

  constructor() { }

  ngOnInit() {
  }

}
